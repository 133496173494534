<template>
  <div class="">
    <TitleWithBackButton
      title="Pagamenti"
      back-route="Homepage"
      icon="mdi-cash-register"
    ></TitleWithBackButton>
    <PaymentsHome
      :style="{
        'pointer-events': canManagePayments ? 'all' : 'none',
        opacity: canManagePayments ? '1' : '0.2',
      }"
    ></PaymentsHome>

    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Utente non abilitato"
      :error-message="errorMessage"
      @close-error="goBack"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";

import PaymentsHome from "@/components/areas/payments/PaymentsHome.vue";
import TitleWithBackButton from "@/components/common/TitleWithBackButton.vue";
export default {
  components: {
    PaymentsHome,
    TitleWithBackButton,
    ErrorDialogComponent,
  },
  data: function () {
    return {
      showErrorDialog: false,
      errorMessage: "",
      paymentsPermission: [],
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Homepage" });
    },
  },
  computed: {
    canManagePayments() {
      if (
        this.paymentsPermission.includes("view")
      )
        return true;
      else {
        this.showErrorDialog = true;
        this.errorMessage = "Accesso alla gestione pagamenti non autorizzato ";
        return false;
      }
    },
  },
  permissions: {
    Payments: {
      bind: "paymentsPermission",
    },
  },
};
</script>

<style>
</style>