<template>
  <v-list-item two-line>
    <v-list-item-icon>
      <v-icon>{{operationIcon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{operation.text}}</v-list-item-title>
      <v-list-item-subtitle>fatta il {{operation.performedAt}}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import paymentMapping from '@/services/payments/paymentNameMappings'

export default {
  name: "RecentOperationResult",
  data: function() {
    return {}
  },
  props: {
    operation: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
  },
  computed: {
    operationIcon() {
      return paymentMapping.getOperationIcon(this.operation.operationName)
    }
  }
}
</script>

<style>

</style>